@import url('bootstrap-icons/font/bootstrap-icons.css');

@font-face {
  font-family: 'INF';
  src: url('./Fonts/INF.woff2') format('woff2');
  font-display: swap;
}

* {
  font-family: 'INF', sans-serif;
  color: rgba(150, 160, 175, 0.75);
}

.container {
  max-width: 960px;
}

.bento {
  border-style: dotted;
  border-color: #212529;
  border-width: 2px;
  border-radius: 24px;
  padding: 0px;
  margin-top: 40px;
}

.bento:last-child {
  padding: 48px !important;
}

.spacer {
  margin-bottom: 60px;
}

.som-hero {
  max-width: 200px;
  margin: auto;
}

.som-title {
  color: #fff;
  text-align: center;
  font-size: 1rem;
}

.som-text {
  text-align: center;
  font-size: 1rem;
}

.som-domain {
  text-align: center;
  font-size: 1rem;
  margin: 0 auto;
}

.som-domain a {
  color: #10a113;
  text-decoration: none;
  margin-left: 5px;
}

.som-domain a:hover {
  text-decoration: underline;
}
